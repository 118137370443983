export const auth = {
  activationPage: {
    loading: 'Activer le compte...',
  },
  fields: {
    contract: {
      label: 'Lu et approuvé',
      placeholder: 'Lu et approuvé',
    },
  },
  footer: {
    socialLinks: {
      facebook: 'https://www.facebook.com/creativeshelter/',
      instagram: 'https://www.instagram.com/creativeshelter/',
      linkedIn: 'https://be.linkedin.com/company/creative-shelter/',
      website: 'https://www.creativeshelter.be/',
    },
    trademark: 'Creative Shelter © {{year}}',
  },
  forgotPasswordPage: {
    errors: {
      ineligible: {
        description:
          "Vous ne pouvez pas demander un nouveau mot de passe si votre compte n'a pas encore été approuvé. Nous vérifierons votre profil et vos activités, vous aurez bientôt de nos nouvelles !",
        title: "En attente d'approbation",
      },
      unverifiedEmail: {
        description:
          "Vous ne pouvez pas demander un nouveau mot de passe si votre adresse e-mail n'a pas été vérifiée. Veuillez vérifier votre boîte mails et vérifier les informations nécessaires.",
        title: 'En attente de vérification',
      },
    },
    form: {
      submitButton: 'Envoyez-moi le lien de réinitialisation par e-mail',
    },
    header: {
      pill: "Oups, j'ai oublié",
      subtitle:
        "Entrez votre adresse e-mail ci-dessous. Nous vous enverrons l'e-mail « Oups mot de passe oublié » nécessaire pour que vous puissiez le réinitialiser !",
      title: 'Mot de passe oublié ?',
    },
    loginLink: 'Se connecter',
    successAlert: {
      description:
        "Les informations permettant de réinitialiser votre mot de passe ont été envoyées à l'adresse e-mail fournie. Vous n'avez pas reçu d'e-mail ? Assurez-vous d'avoir saisi la bonne adresse e-mail.",
      title: 'E-mail envoyé !',
    },
    tokenExpiredAlert: {
      description: 'Demandez un nouveau lien ici pour modifier votre mot de passe.',
      title: 'Lien expiré',
    },
  },
  loginPage: {
    accountantLoginCta: {
      action: '<accountantLink href="https://api.creativeshelter.be/accountants/login">Connectez-vous ici </accountantLink>',
      message: 'Vous êtes comptable ?',
    },
    errors: {
      ineligible: {
        description:
          "Vous ne pouvez pas encore vous connecter car vous n'êtes pas automatiquement éligible. Nous vérifierons votre profil et vos activités, nous vous en dirons plus bientôt !",
        title: "En attente d'approbation",
      },
      invalidEmailOrPassword: "Oups ! Une erreur s'est produite, votre adresse e-mail et/ou votre mot de passe sont incorrects.",
      unverifiedEmail: {
        description: "Votre adresse e-mail n'a pas encore été vérifiée. Consultez votre boîte mail et vérifiez adresse e-mail.",
        title: 'En attente de vérification',
      },
    },
    forgotPasswordLink: 'Mot de passe oublié ?',
    form: {
      emailField: {
        label: 'Adresse e-mail',
      },
      passwordField: {
        label: 'Mot de passe',
      },
      submitButton: 'Connexion',
    },
    header: {
      pill: 'Connexion',
      subtitle: 'Connectez-vous pour vous dirigez vers Craft.',
      title: 'Bienvenue!',
    },
    registerCta: {
      action: "S'enregistrer",
      message: "Vous n'avez pas encore de compte ?",
    },
    sessionExpiredAlert: {
      description: 'Veuillez vous reconnecter pour continuer.',
      title: 'Session expirée',
    },
  },
  registrationPage: {
    common: {
      header: {
        pill: 'Inscription',
      },
      loginCta: {
        action: 'Se connecter',
        message: 'Vous avez déjà un compte ?',
      },
      previousStepButton: 'Précédent',
      referralCode: {
        addButton: 'Code de réduction',
        applied: 'Réduction !',
        editButton: 'Modifier',
        form: {
          invitationsField: {
            description:
              "Nous constatons que plusieurs freelances vous ont invité·e. Choisissez l'invitation que vous souhaitez accepter ou saisissez vous-même un (autre) code de réduction.",
            invitedAt: 'Invité·e le {{date}}',
          },
          referralCodeField: {
            errors: {
              generic: 'Le code de réduction ne peut pas être validé pour le moment.',
              invalid: "Ce code de réduction n'est pas valide.",
            },
            invitationDescription: 'Vous êtes invité·e par{{name}} avec le code de réduction<code>{{code}}</code> .',
            label: 'Code de réduction',
          },
          submitButton: 'Appliquer',
        },
        types: {
          fromAccountant: 'Le code de réduction{{referralCode}} du comptable{{name}} a été appliqué avec succès !',
          fromAdmin: {
            ZOMER2024:
              'La promo estivale {{referralCode}} est appliquée! Cela signifie tester Creative Shelter gratuitement pendant 60 jours et bénéficier d’avantages supplémentaires !',
            generic: 'Le code de réduction {{referralCode}} a été appliqué avec succès !',
          },
          fromUser:
            "Réduction grâce au parrainage {{referralCode}} par{{name}} ! Créez votre première facture et profitez tous les deux d'une réduction !",
        },
      },
    },
    stepAccountInformation: {
      alertNoElibibleNacebelCodes: {
        description:
          'Vous pouvez poursuivre votre inscription, mais nous activerons votre compte après avoir examiné manuellement vos activités.',
        title: "Vous n'êtes pas éligible par défaut",
      },
      form: {
        emailField: {
          errors: {
            exists: 'Cette adresse e-mail est déjà utilisée, veuillez vérifier.',
            generic:
              'Une erreur s\'est produite lors de la validation de votre adresse e-mail. </email>Si le problème persiste, veuillez contacter <email href="mailto:service@creativeshelter.be"> service@creativeshelter.be.',
          },
          label: 'Adresse e-mail',
        },
        passwordField: {
          description: "Choisissez un mot de passe sécurisé d'au moins 8 caractères. Et n'hésitez pas à le rendre original !",
          label: 'Mot de passe',
        },
        phoneNumberField: {
          label: 'Numéro de téléphone portable',
        },
        submitButton: 'Suivant',
      },
      header: {
        subtitle: 'Êtes-vous prêt·e  ?',
        title: 'Bonjour {{name}}',
      },
    },
    stepBusinessInformation: {
      alertItDevelopers: {
        description:
          "Depuis 2024, vous ne pouvez plus recevoir de rémunération en droits d'auteur pour les prestations de programmes informatiques. Vous êtes toujours éligible à d’autres activités créatives.",
        title: 'Important ! Développeur informatique ? Lisez la suite.',
      },
      errors: {
        fallback: "Une erreur inattendue s'est produite lors de votre inscription.",
        httpError:
          'Une erreur s\'est produite lors de l\'inscription. </email>Si le problème persiste, veuillez contacter <email href="mailto:service@creativeshelter.be"> service@creativeshelter.be.',
      },
      form: {
        mainOccupationField: {
          label: 'Statut',
          options: {
            mainOccupation: 'Activité principale',
            secondaryOccupation: 'Activité complémentaire',
          },
        },
        otherActivitiesField: {
          label: 'Décrivez vos activités (créatives) supplémentaires',
        },
        primaryActivityField: {
          label: 'Activité principale',
        },
        secondaryActivityField: {
          label: "Avez-vous d'autres activités (créatives) ?",
        },
        submitButton: 'Créer un compte',
      },
      header: {
        subtitle: 'Parlez-nous un peu de votre entreprise individuelle.',
        title: 'Bonjour {{name}}',
      },
    },
    stepCompany: {
      alert: {
        description:
          'Notre plateforme Craft est conçue pour les entreprises individuelles, et ne s\'applique donc pas à vous. Heureusement, Creative Shelter propose également une solution pour les entreprises.<break/> Intéressé·e ? <kiiaLink href="https://www.creativeshelter.be/kom-ik-in-aanmerking">Vérifiez votre éligibilité</kiiaLink>.',
        title: 'Creative Shelter pour les entreprises',
      },
      changeVatNumber: {
        action: 'Adapter',
        message: "Le numéro de TVA n'est pas correct ?",
      },
      header: {
        subtitle: 'Nous voyons que vous avez une entreprise.',
        title: 'Bonjour, gérant·e de {{name}}',
      },
    },
    stepSuccess: {
      header: {
        subtitle: {
          eligible: 'Votre compte a été créé avec succès !',
          ineligible: 'Données bien reçues !',
        },
        title: "Vous avez atteint la ligne d'arrivée !",
      },
      linkAccountant: {
        errors: {
          generic: "Une erreur s'est produite lors de la connexion avec votre comptable.",
        },
        form: {
          accountantEmailAddressField: {
            description:
              "N'hésitez pas à saisir l'adresse e-mail de votre comptable ci-dessous. Ne connaît-il·elle pas encore Creative Shelter ? Alors nous aimerions nous présenter !",
            label: 'Travaillez-vous avec un comptable ?',
          },
        },
        success: {
          message: 'Nous avons bien reçu les informations de votre comptable, nous y travaillerons !',
          title: "Merci pour l'info !",
        },
      },
      message: {
        eligible:
          'Un e-mail a été envoyé à <bold> {{email}}</bold>. Suivez les instructions contenues dans cet e-mail pour activer votre compte.',
        ineligible:
          'Nous vérifierons si vous êtes éligible à la facturation via Creative Shelter. <break/>Vous pouvez déjà fermer cette fenêtre, nous vous informerons rapidement par e-mail !',
      },
    },
    stepVatNumber: {
      alert: {
        kboNotAvailable: {
          description:
            'Wegens problemen bij het KBO kunnen we op dit moment geen btw-nummers valideren. Gelieve even te wachten of later opnieuw te proberen.<break />Onze excuses voor het ongemak!',
          title: 'KBO niet bereikbaar',
        },
      },
      form: {
        submitButton: 'Allons-y !',
        vatNumberField: {
          description:
            'Vous n\'avez pas encore de numéro de TVA ? <faqLink href="https://www.creativeshelter.be/knowledge-hub/kan-ik-mij-al-aansluiten-bij-creative-shelter-als-mijn-btw-nummer-nog-in-aanvraag-is">Lisez </faqLink> ce qu\'il faut faire ici.',
          errors: {
            exists: 'Il existe déjà un compte avec ce numéro de TVA, merci de vérifier ?',
            fallback: "Une erreur inattendue s'est produite lors de la validation de votre numéro de TVA.",
            httpError:
              'Une erreur s\'est produite lors de la validation de votre numéro de TVA. </email>Si le problème persiste, veuillez contacter <email href="mailto:service@creativeshelter.be"> service@creativeshelter.be.',
            inactiveVatNumber:
              'Votre numéro de TVA n’est pas encore actif selon la BCE. Vous ne pouvez vous inscrire qu’avec un numéro de TVA actif, veuillez réessayer plus tard.',
            invalidVatNumber: 'Le numéro de TVA indiqué n’existe pas dans la BCE. Merci de vérifier ?',
            vatNumberChecksum: "Le numéro de TVA ne semble pas correct. Merci de vérifier s'il y a une erreur.",
            vatNumberLength: 'Un numéro de TVA est composé de 10 chiffres, le premier chiffre étant 0 ou 1.',
          },
          label: 'Numéro de TVA',
        },
      },
      header: {
        subtitle: 'Créez votre compte Creative Shelter gratuit ici',
        title: "Tirez le meilleur parti de vos droits d'auteur",
      },
    },
  },
  resendVerificationEmail: {
    error: "Une erreur s'est produite lors de l'envoi de l'e-mail de vérification. Veuillez contacter service@creativeshelter.be.",
    send: "Vous n'avez rien reçu ? <button>Cliquez ici </button> pour renvoyer l'e-mail.",
    sent: "Nous avons envoyé un nouvel e-mail de vérification à <bold> {{emailAddress}}</bold>. Vous n'avez toujours rien reçu ? Veuillez réessayer après {{seconds}} secondes.",
  },
  resetPasswordPage: {
    errors: {
      ineligible: {
        description:
          "Vous ne pouvez pas modifier votre mot de passe si votre compte n'est pas approuvé. Nous vérifierons votre profil et vos activités, nous vous en dirons plus bientôt !",
        title: "En attente d'approbation",
      },
      unverifiedEmail: {
        description:
          "Vous ne pouvez pas modifier votre mot de passe tant que votre adresse e-mail n'est pas vérifiée. Consultez votre boîte mails et vérifiez le nécessaire.",
        title: 'En attente de vérification',
      },
    },
    form: {
      newPasswordField: {
        label: 'Nouveau mot de passe',
      },
      submitButton: 'Modifier le mot de passe',
    },
    header: {
      subtitle: 'Choisissez un nouveau mot de passe pour votre compte Craft.',
      title: 'Réinitialisation du mot de passe',
    },
  },
  sidebars: {
    checklist: {
      features: {
        first: "Vos droits d'auteur gérés rapidement et efficacement via la plateforme Craft.",
        fourth: 'Un service au top ? Inclus standard chez Creative Shelter !',
        second: 'Tirez le meilleur parti de vos factures créatives, en toute simplicité.',
        third:
          'Chez nous, vous êtes triplement assuré·e <insuranceFaqLink href="https://www.creativeshelter.be/knowledge-hub/hoe-ben-ik-verzekerd-bij-creative-shelter"> trois fois. </insuranceFaqLink>',
      },
    },
    kiia: {
      eligibilityTest: {
        button: 'Faites la vérification',
        description: 'Un tel avantage s’accompagne de certaines conditions.<break /> Voulez-vous être sûr de répondre à ces conditions ?',
        kiiaLink: 'https://www.creativeshelter.be/kom-ik-in-aanmerking',
        title: 'Êtes-vous éligible ?',
      },
      text: {
        first:
          "La seule plateforme de facturation qui vous rapporte de l'argent. <break/>Gagnez plus en tant que freelance créatif en faisant valoir vos droits d'auteur via Creative Shelter.",
        second: "Vos droits d'auteur valent de l'or.",
      },
    },
    testimonials: {
      indiana: {
        body: "Il m'a fallu un certain temps pour m'inscrire et pour tout mettre en ordre, mais je suis tellement contente de l'avoir fait. Depuis lors, Creative Shelter est un onglet qui est ouvert par défaut et que j'utilise tous les jours. C'est très agréable de voir combien de bénéfices supplémentaires je réalise à chaque fois par rapport à une facture standard.",
        name: 'Indiana De Clerq',
        title: 'Plus de bénéfices par rapport à une facture standard',
      },
      joelle: {
        body: 'Très satisfait de Creative Shelter ! 😊 Ils sont très conviviaux, précis et clairs, offrent toujours un service avec le sourire et un suivi rapide et efficace. Hautement recommandé!',
        name: 'Joëlle Batens',
        title: 'Facile à utiliser, précis et clair',
      },
      toon: {
        body: 'Utilisateur très satisfait de la plateforme Creative Shelter depuis plus de 5 ans, fortement recommandé pour tout créatif indépendant !',
        name: 'Toon Van Craeyevelt',
        title: 'Recommandé pour tout créatif indépendant',
      },
    },
  },
};
